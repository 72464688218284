import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CourseModule from '../components/courses/CourseModule';
import CourseProgress from '../components/courses/CourseProgress';

const Web3Fundamentals = () => {
  const navigate = useNavigate();
  const [completedModules, setCompletedModules] = useState([]);

  const modules = [
    {
      id: 'intro',
      title: 'Introduction to Web3',
      duration: '1 week',
      description: 'Understanding the fundamental concepts of Web3 and its evolution from Web2.',
      lessons: [
        { type: 'video', title: 'What is Web3?', duration: '15 min' },
        { type: 'reading', title: 'Evolution of the Web', duration: '20 min' },
        { type: 'code', title: 'Your First Web3 Interaction', duration: '30 min' },
        { type: 'quiz', title: 'Web3 Fundamentals Quiz', duration: '15 min' }
      ]
    },
    {
      id: 'blockchain',
      title: 'Blockchain Basics',
      duration: '1 week',
      description: 'Learn about blockchain technology and its core components.',
      lessons: [
        { type: 'video', title: 'Blockchain Architecture', duration: '20 min' },
        { type: 'reading', title: 'Consensus Mechanisms', duration: '25 min' },
        { type: 'code', title: 'Creating a Simple Blockchain', duration: '45 min' },
        { type: 'quiz', title: 'Blockchain Concepts Quiz', duration: '15 min' }
      ]
    }
  ];

  const handleModuleComplete = (moduleId) => {
    setCompletedModules(prev => 
      prev.includes(moduleId) 
        ? prev.filter(id => id !== moduleId)
        : [...prev, moduleId]
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-3xl font-bold mb-2">Web3 Fundamentals</h1>
        <p className="text-gray-600 mb-8">Learn the core concepts of Web3 and blockchain technology</p>

        <CourseProgress 
          progress={completedModules.length}
          totalModules={modules.length}
        />

        <div className="space-y-4">
          {modules.map((module, index) => (
            <CourseModule
              key={module.id}
              module={module}
              index={index}
              isCompleted={completedModules.includes(module.id)}
              onComplete={handleModuleComplete}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Web3Fundamentals;
