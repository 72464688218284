import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlayCircle, BookOpen, Code, FileText, CheckCircle } from 'lucide-react';

const CourseModule = ({ module, index, isCompleted, onComplete }) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleLessonClick = (lessonId) => {
    navigate(`/lessons/${lessonId}`);
  };

  const getLessonIcon = (type) => {
    switch (type) {
      case 'video': return <PlayCircle className="text-blue-500" size={20} />;
      case 'reading': return <BookOpen className="text-purple-500" size={20} />;
      case 'code': return <Code className="text-green-500" size={20} />;
      case 'quiz': return <FileText className="text-orange-500" size={20} />;
      default: return null;
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md mb-4">
      <div 
        className="p-4 flex items-center justify-between cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center">
          <span className="w-8 h-8 rounded-full bg-blue-100 text-blue-500 flex items-center justify-center mr-3">
            {index + 1}
          </span>
          <h3 className="text-lg font-semibold">{module.title}</h3>
        </div>
        {isCompleted ? (
          <CheckCircle className="text-green-500 w-6 h-6" />
        ) : (
          <div className="text-gray-500">{module.duration}</div>
        )}
      </div>

      {isExpanded && (
        <div className="p-4 border-t">
          <p className="text-gray-600 mb-4">{module.description}</p>
          <div className="space-y-3">
            {module.lessons.map((lesson, idx) => (
              <button
                key={idx}
                onClick={() => handleLessonClick(lesson.id)}
                className="w-full flex items-center p-3 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <div className="mr-3">
                  {getLessonIcon(lesson.type)}
                </div>
                <div className="flex-1 text-left">
                  <div className="font-medium">{lesson.title}</div>
                  <div className="text-sm text-gray-500">{lesson.duration}</div>
                </div>
              </button>
            ))}
          </div>

          <div className="mt-6">
            <button
              onClick={(e) => {
                e.stopPropagation();
                onComplete(module.id);
              }}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
              {isCompleted ? 'Revisit Module' : 'Start Module'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseModule;