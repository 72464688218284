import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import './styles/index.css';

// Import pages
import Introduction from './pages/Introduction';
import Features from './pages/Features';
import Technologies from './pages/Technologies';
import Examples from './pages/Examples';
import Playground from './pages/Playground';
import Learn from './pages/Learn';
import Network from './pages/Network';
import Web3Fundamentals from './pages/Web3Fundamentals';
import SmartContractDev from './pages/SmartContractDev';
import DAppDev from './pages/DAppDev';

const App = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-white shadow-lg sticky top-0 z-50">
        <div className="max-w-6xl mx-auto px-4 py-3">
          <div className="flex items-center justify-between">
            <Link to="/" className="text-xl font-semibold text-gray-700">Web3 Guide</Link>
            <div className="flex space-x-6">
              <Link to="/introduction" className="text-gray-600 hover:text-gray-900">Introduction</Link>
              <Link to="/features" className="text-gray-600 hover:text-gray-900">Features</Link>
              <Link to="/technologies" className="text-gray-600 hover:text-gray-900">Technologies</Link>
              <Link to="/examples" className="text-gray-600 hover:text-gray-900">Examples</Link>
              <Link to="/playground" className="text-gray-600 hover:text-gray-900">Playground</Link>
              <Link to="/learn" className="text-gray-600 hover:text-gray-900">Learn</Link>
              <Link to="/network" className="text-gray-600 hover:text-gray-900">Network</Link>
            </div>
          </div>
        </div>
      </nav>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/introduction" element={<Introduction />} />
        <Route path="/features" element={<Features />} />
        <Route path="/technologies" element={<Technologies />} />
        <Route path="/examples" element={<Examples />} />
        <Route path="/playground" element={<Playground />} />
        <Route path="/learn" element={<Learn />} />
        <Route path="/network" element={<Network />} />
        <Route path="/courses/web3-fundamentals" element={<Web3Fundamentals />} />
        <Route path="/courses/smart-contract-dev" element={<SmartContractDev />} />
        <Route path="/courses/dapp-dev" element={<DAppDev />} />
      </Routes>

      <footer className="bg-gray-800 text-white py-8">
        <div className="max-w-6xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h4 className="text-lg font-semibold mb-4">About</h4>
              <p className="text-gray-400">Exploring the future of the decentralized web through education and interactive experiences.</p>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Resources</h4>
              <ul className="space-y-2">
                <li><Link to="/docs" className="text-gray-400 hover:text-white">Documentation</Link></li>
                <li><Link to="/api" className="text-gray-400 hover:text-white">API Reference</Link></li>
                <li><Link to="/community" className="text-gray-400 hover:text-white">Community</Link></li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Contact</h4>
              <ul className="space-y-2">
                <li><Link to="/support" className="text-gray-400 hover:text-white">Support</Link></li>
                <li><Link to="/discord" className="text-gray-400 hover:text-white">Discord</Link></li>
                <li><Link to="/twitter" className="text-gray-400 hover:text-white">Twitter</Link></li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Legal</h4>
              <ul className="space-y-2">
                <li><Link to="/privacy" className="text-gray-400 hover:text-white">Privacy Policy</Link></li>
                <li><Link to="/terms" className="text-gray-400 hover:text-white">Terms of Service</Link></li>
                <li><Link to="/cookies" className="text-gray-400 hover:text-white">Cookie Policy</Link></li>
              </ul>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-700 text-center">
            <p>&copy; 2024 Web3 Guide. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

const Home = () => {
  return (
    <div>
      <section className="bg-gradient-to-r from-blue-500 to-purple-600 py-20">
        <div className="max-w-4xl mx-auto text-center px-4">
          <h1 className="text-5xl font-bold text-white mb-4">Welcome to Web 3.0</h1>
          <p className="text-xl text-white mb-8">Discover the next evolution of the internet</p>
          <Link 
            to="/introduction"
            className="inline-flex items-center bg-white text-blue-600 px-6 py-3 rounded-lg font-semibold hover:bg-gray-100 transition duration-300"
          >
            Get Started
            <ArrowRight className="ml-2" size={20} />
          </Link>
        </div>
      </section>
    </div>
  );
};

export default App;
