import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Book, CheckCircle, Clock, Trophy, ArrowRight, BookOpen } from 'lucide-react';

const Learn = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('all');
  
  const courses = [
    {
      title: 'Web3 Fundamentals',
      description: 'Learn the core concepts of Web3 and blockchain technology',
      duration: '4 weeks',
      level: 'Beginner',
      category: 'fundamentals',
      path: 'web3-fundamentals',
      progress: 0,
      modules: [
        'Introduction to Web3',
        'Blockchain Basics',
        'Smart Contracts',
        'Decentralized Applications'
      ]
    },
    {
      title: 'Smart Contract Development',
      description: 'Master Solidity programming and smart contract development',
      duration: '6 weeks',
      level: 'Intermediate',
      category: 'development',
      path: 'smart-contract-dev',
      progress: 0,
      modules: [
        'Solidity Basics',
        'Contract Design Patterns',
        'Security Best Practices',
        'Testing & Deployment'
      ]
    },
    {
      title: 'DApp Development',
      description: 'Build full-stack decentralized applications',
      duration: '8 weeks',
      level: 'Advanced',
      category: 'development',
      path: 'dapp-dev',
      progress: 0,
      modules: [
        'Web3.js Integration',
        'Frontend Development',
        'State Management',
        'IPFS & Decentralized Storage'
      ]
    },
    {
      title: 'Blockchain Security',
      description: 'Learn advanced security concepts and best practices',
      duration: '6 weeks',
      level: 'Advanced',
      category: 'security',
      progress: 0,
      path: 'blockchain-security',
      modules: [
        'Security Fundamentals',
        'Smart Contract Auditing',
        'Common Vulnerabilities',
        'Security Tools & Testing'
      ]
    }
  ];

  const tabs = [
    { id: 'all', label: 'All Courses' },
    { id: 'fundamentals', label: 'Fundamentals' },
    { id: 'development', label: 'Development' },
    { id: 'security', label: 'Security' }
  ];

  const filteredCourses = activeTab === 'all' 
    ? courses 
    : courses.filter(course => course.category === activeTab);

  const getLevelColor = (level) => {
    switch (level) {
      case 'Beginner': return 'text-green-500';
      case 'Intermediate': return 'text-blue-500';
      case 'Advanced': return 'text-purple-500';
      default: return 'text-gray-500';
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Learning Path
          </h1>
          <p className="text-xl text-gray-600">
            Start your journey into Web3 development
          </p>
        </div>

        {/* Course Filters */}
        <div className="flex justify-center mb-8">
          <div className="inline-flex bg-white rounded-lg shadow p-1">
            {tabs.map(tab => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-150 ${
                  activeTab === tab.id
                    ? 'bg-blue-500 text-white'
                    : 'text-gray-500 hover:text-gray-900'
                }`}
              >
                {tab.label}
              </button>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredCourses.map((course, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300"
            >
              <div className="flex items-center mb-4">
                <BookOpen className="text-blue-500 mr-2" />
                <h2 className="text-xl font-bold">{course.title}</h2>
              </div>
              
              <p className="text-gray-600 mb-4">
                {course.description}
              </p>
              
              <div className="flex items-center text-sm text-gray-500 mb-4">
                <Clock className="w-4 h-4 mr-1" />
                <span>{course.duration}</span>
                <span className="mx-2">•</span>
                <Trophy className="w-4 h-4 mr-1" />
                <span className={getLevelColor(course.level)}>{course.level}</span>
              </div>

              <div className="space-y-2 mb-6">
                {course.modules.map((module, idx) => (
                  <div
                    key={idx}
                    className="flex items-center text-gray-600"
                  >
                    <CheckCircle className="w-4 h-4 text-green-500 mr-2" />
                    <span>{module}</span>
                  </div>
                ))}
              </div>

              <button
                onClick={() => navigate(`/courses/${course.path}`)}
                className="w-full mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-300 flex items-center justify-center"
              >
                Start Learning
                <ArrowRight className="ml-2 w-4 h-4" />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Learn;
