import React from 'react';
import { Code, Wallet, Image } from 'lucide-react';

const Examples = () => {
  const examples = [
    {
      title: 'DeFi Applications',
      icon: <Wallet className="w-12 h-12 text-blue-500" />,
      description: 'Decentralized finance applications showcasing Web3 capabilities.',
      demos: [
        {
          name: 'Token Swap',
          code: `// Token Swap Contract
contract TokenSwap {
    function swap(
        address tokenIn,
        address tokenOut,
        uint256 amountIn
    ) external returns (uint256) {
        // Swap logic
    }
}`,
          description: 'Swap tokens directly without intermediaries'
        },
        {
          name: 'Yield Farming',
          code: `// Yield Farm Contract
contract YieldFarm {
    function stake(uint256 amount) external {
        // Staking logic
    }
    
    function harvest() external {
        // Reward distribution
    }
}`,
          description: 'Earn rewards by providing liquidity'
        }
      ]
    },
    {
      title: 'NFT Marketplace',
      icon: <Image className="w-12 h-12 text-purple-500" />,
      description: 'Create, buy, and sell unique digital assets.',
      demos: [
        {
          name: 'NFT Minting',
          code: `// NFT Contract
contract NFT is ERC721 {
    function mint(string memory tokenURI) 
        external 
        returns (uint256) {
        // Minting logic
    }
}`,
          description: 'Create unique digital assets'
        },
        {
          name: 'NFT Trading',
          code: `// Marketplace Contract
contract NFTMarket {
    function listNFT(
        uint256 tokenId, 
        uint256 price
    ) external {
        // Listing logic
    }
}`,
          description: 'Trade NFTs in a decentralized marketplace'
        }
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Web3 Examples
          </h1>
          <p className="text-xl text-gray-600">
            Explore real-world applications of Web3 technology
          </p>
        </div>

        <div className="space-y-12">
          {examples.map((example, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg p-6">
              <div className="flex items-center mb-6">
                {example.icon}
                <div className="ml-4">
                  <h2 className="text-2xl font-bold">
                    {example.title}
                  </h2>
                  <p className="text-gray-600">
                    {example.description}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {example.demos.map((demo, idx) => (
                  <div key={idx} className="bg-gray-50 rounded-lg p-6">
                    <h3 className="text-xl font-semibold mb-2">
                      {demo.name}
                    </h3>
                    <p className="text-gray-600 mb-4">
                      {demo.description}
                    </p>
                    <pre className="bg-gray-900 text-gray-100 p-4 rounded-lg overflow-x-auto">
                      <code>{demo.code}</code>
                    </pre>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Examples;
