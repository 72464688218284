import React, { useState } from 'react';
import { Code2, Play, Save } from 'lucide-react';

const Playground = () => {
  const [code, setCode] = useState(`// SPDX-License-Identifier: MIT
pragma solidity ^0.8.0;

contract SimpleToken {
    string public name;
    string public symbol;
    uint8 public decimals = 18;
    uint256 public totalSupply;

    mapping(address => uint256) public balanceOf;

    event Transfer(
        address indexed from, 
        address indexed to, 
        uint256 value
    );

    constructor(
        string memory _name,
        string memory _symbol,
        uint256 _totalSupply
    ) {
        name = _name;
        symbol = _symbol;
        totalSupply = _totalSupply;
        balanceOf[msg.sender] = _totalSupply;
    }

    function transfer(
        address to, 
        uint256 amount
    ) external returns (bool) {
        require(balanceOf[msg.sender] >= amount, 
            "Insufficient balance");
        
        balanceOf[msg.sender] -= amount;
        balanceOf[to] += amount;
        
        emit Transfer(msg.sender, to, amount);
        return true;
    }
}`);

  const [output, setOutput] = useState('');
  const [isCompiling, setIsCompiling] = useState(false);

  const handleCompile = async () => {
    setIsCompiling(true);
    setOutput('Compiling...');
    
    // Simulate compilation delay
    await new Promise(resolve => setTimeout(resolve, 1500));
    
    setOutput('Compilation successful!\nContract is ready for deployment.');
    setIsCompiling(false);
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Smart Contract Playground
          </h1>
          <p className="text-xl text-gray-600">
            Write, compile, and test smart contracts in your browser
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-lg">
          <div className="flex justify-between items-center p-4 border-b">
            <div className="flex items-center">
              <Code2 className="text-blue-500 mr-2" />
              <h2 className="text-xl font-semibold">
                Solidity Editor
              </h2>
            </div>
            <div className="space-x-2">
              <button
                onClick={handleCompile}
                disabled={isCompiling}
                className={`px-4 py-2 rounded-lg flex items-center ${
                  isCompiling
                    ? 'bg-gray-300 cursor-not-allowed'
                    : 'bg-blue-500 hover:bg-blue-600 text-white'
                }`}
              >
                <Play className="w-4 h-4 mr-2" />
                {isCompiling ? 'Compiling...' : 'Compile'}
              </button>
              <button
                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 flex items-center"
              >
                <Save className="w-4 h-4 mr-2" />
                Save
              </button>
            </div>
          </div>
          
          <div className="p-4">
            <textarea
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="w-full h-96 font-mono text-sm p-4 bg-gray-50 border rounded-lg focus:ring-2 focus:ring-blue-500"
              spellCheck="false"
            />
            
            {output && (
              <div className="mt-4 p-4 bg-gray-900 text-green-400 rounded-lg">
                <pre>{output}</pre>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Playground;
