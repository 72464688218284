import React, { useState, useEffect } from 'react';
import { Activity, Database, TrendingUp, Users } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Network = () => {
  const [stats, setStats] = useState({
    nodes: 0,
    transactions: 0,
    blockTime: 0,
    gasPrice: 0
  });

  const [chartData] = useState([
    { time: '00:00', transactions: 1200, gasPrice: 25 },
    { time: '04:00', transactions: 1400, gasPrice: 28 },
    { time: '08:00', transactions: 2100, gasPrice: 32 },
    { time: '12:00', transactions: 1800, gasPrice: 30 },
    { time: '16:00', transactions: 2400, gasPrice: 35 },
    { time: '20:00', transactions: 2000, gasPrice: 33 }
  ]);

  useEffect(() => {
    // Simulate real-time network stats updates
    const interval = setInterval(() => {
      setStats({
        nodes: Math.floor(Math.random() * 1000) + 5000,
        transactions: Math.floor(Math.random() * 10000) + 50000,
        blockTime: (Math.random() * 2 + 12).toFixed(1),
        gasPrice: Math.floor(Math.random() * 20) + 20
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Network Status
          </h1>
          <p className="text-xl text-gray-600">
            Real-time network statistics and monitoring
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center mb-4">
              <Users className="text-blue-500 mr-2" />
              <h3 className="text-lg font-semibold">Active Nodes</h3>
            </div>
            <p className="text-3xl font-bold">
              {stats.nodes.toLocaleString()}
            </p>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center mb-4">
              <Activity className="text-green-500 mr-2" />
              <h3 className="text-lg font-semibold">Transactions</h3>
            </div>
            <p className="text-3xl font-bold">
              {stats.transactions.toLocaleString()}
            </p>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center mb-4">
              <Database className="text-purple-500 mr-2" />
              <h3 className="text-lg font-semibold">Block Time</h3>
            </div>
            <p className="text-3xl font-bold">{stats.blockTime}s</p>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center mb-4">
              <TrendingUp className="text-orange-500 mr-2" />
              <h3 className="text-lg font-semibold">Gas Price</h3>
            </div>
            <p className="text-3xl font-bold">{stats.gasPrice} Gwei</p>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-lg p-6">
          <h3 className="text-xl font-semibold mb-6">Network Activity</h3>
          <div className="h-96">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip />
                <Legend />
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="transactions"
                  stroke="#3B82F6"
                  name="Transactions"
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="gasPrice"
                  stroke="#10B981"
                  name="Gas Price (Gwei)"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Network;
