import React from 'react';

const Introduction = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <h2 className="text-4xl font-bold mb-8">What is Web 3.0?</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="space-y-4">
          <p className="text-gray-600">
            Web 3.0, or the Semantic Web, represents the next evolution of internet technology. It combines artificial intelligence, machine learning, and blockchain technology to create a more intelligent, autonomous, and decentralized web experience.
          </p>

          <p className="text-gray-600">
            Unlike its predecessors, Web 3.0 focuses on:
          </p>

          <ul className="list-disc list-inside space-y-2 text-gray-600 ml-4">
            <li>Decentralization and user autonomy</li>
            <li>Artificial Intelligence and Machine Learning</li>
            <li>Semantic Web technologies</li>
            <li>Spatial Web and 3D graphics</li>
          </ul>
        </div>

        <div className="bg-gray-100 p-6 rounded-lg">
          <h3 className="text-2xl font-semibold mb-4">Evolution of the Web</h3>
          
          <div className="space-y-4">
            <div className="flex items-center space-x-4">
              <span className="text-lg font-bold">Web 1.0:</span>
              <span>Read-only, static websites</span>
            </div>

            <div className="flex items-center space-x-4">
              <span className="text-lg font-bold">Web 2.0:</span>
              <span>Social media, interactive content</span>
            </div>

            <div className="flex items-center space-x-4">
              <span className="text-lg font-bold">Web 3.0:</span>
              <span>Semantic web, AI-driven, decentralized</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
