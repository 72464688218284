import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CourseModule from '../components/courses/CourseModule';
import CourseProgress from '../components/courses/CourseProgress';

const SmartContractDev = () => {
  const navigate = useNavigate();
  const [completedModules, setCompletedModules] = useState([]);

  const modules = [
    {
      id: 'solidity-basics',
      title: 'Solidity Basics',
      duration: '2 weeks',
      description: 'Learn the fundamentals of Solidity programming language.',
      lessons: [
        { type: 'video', title: 'Introduction to Solidity', duration: '20 min' },
        { type: 'reading', title: 'Data Types & Variables', duration: '30 min' },
        { type: 'code', title: 'Your First Smart Contract', duration: '45 min' },
        { type: 'quiz', title: 'Solidity Basics Quiz', duration: '20 min' }
      ]
    },
    {
      id: 'design-patterns',
      title: 'Contract Design Patterns',
      duration: '2 weeks',
      description: 'Master common smart contract design patterns and best practices.',
      lessons: [
        { type: 'video', title: 'Common Design Patterns', duration: '25 min' },
        { type: 'reading', title: 'Security Considerations', duration: '35 min' },
        { type: 'code', title: 'Implementing Design Patterns', duration: '50 min' },
        { type: 'quiz', title: 'Design Patterns Quiz', duration: '20 min' }
      ]
    }
  ];

  const handleModuleComplete = (moduleId) => {
    setCompletedModules(prev => 
      prev.includes(moduleId) 
        ? prev.filter(id => id !== moduleId)
        : [...prev, moduleId]
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-3xl font-bold mb-2">Smart Contract Development</h1>
        <p className="text-gray-600 mb-8">Master Solidity programming and smart contract development</p>

        <CourseProgress 
          progress={completedModules.length}
          totalModules={modules.length}
        />

        <div className="space-y-4">
          {modules.map((module, index) => (
            <CourseModule
              key={module.id}
              module={module}
              index={index}
              isCompleted={completedModules.includes(module.id)}
              onComplete={handleModuleComplete}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SmartContractDev;
