import React from 'react';
import { Circle } from 'lucide-react';

const CourseProgress = ({ progress, totalModules }) => {
  const percentage = Math.round((progress / totalModules) * 100);

  return (
    <div className="bg-white rounded-lg shadow-md p-4 mb-6">
      <div className="flex items-center justify-between mb-2">
        <h3 className="font-semibold">Course Progress</h3>
        <span className="text-blue-500">{percentage}%</span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2">
        <div 
          className="bg-blue-500 rounded-full h-2 transition-all duration-300"
          style={{ width: `${percentage}%` }}
        />
      </div>
      <div className="mt-2 text-sm text-gray-500">
        {progress} of {totalModules} modules completed
      </div>
    </div>
  );
};

export default CourseProgress;
