import React from 'react';
import { 
  Database, 
  Brain, 
  Binary,
  Shield, 
  Cloud,
  Cpu
} from 'lucide-react';

const Technologies = () => {
  const technologies = [
    {
      icon: <Database className="w-12 h-12 text-blue-500" />,
      title: 'Blockchain',
      description: 'Distributed ledger technology enabling secure, transparent transactions.',
      items: [
        'Smart Contracts',
        'Consensus Mechanisms',
        'Cryptographic Security',
        'Distributed Storage'
      ]
    },
    {
      icon: <Brain className="w-12 h-12 text-blue-500" />,
      title: 'Artificial Intelligence',
      description: 'Machine learning and neural networks powering intelligent systems.',
      items: [
        'Natural Language Processing',
        'Machine Learning Models',
        'Neural Networks',
        'Pattern Recognition'
      ]
    },
    {
      icon: <Binary className="w-12 h-12 text-blue-500" />,
      title: 'Semantic Web',
      description: 'Technologies enabling machines to understand web content.',
      items: [
        'RDF',
        'OWL',
        'SPARQL',
        'Linked Data'
      ]
    },
    {
      icon: <Shield className="w-12 h-12 text-blue-500" />,
      title: 'Security',
      description: 'Advanced cryptography and security protocols.',
      items: [
        'Zero-Knowledge Proofs',
        'Homomorphic Encryption',
        'Digital Signatures',
        'Access Control'
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Core Technologies</h1>
          <p className="text-xl text-gray-600">The foundational technologies powering Web 3.0</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {technologies.map((tech, index) => (
            <div 
              key={index}
              className="bg-white rounded-lg shadow-md p-6 hover:shadow-xl transition-shadow duration-300"
            >
              <div className="flex items-center mb-4">
                {tech.icon}
                <h3 className="text-xl font-semibold ml-4">{tech.title}</h3>
              </div>
              <p className="text-gray-600 mb-4">{tech.description}</p>
              <ul className="space-y-2">
                {tech.items.map((item, idx) => (
                  <li key={idx} className="flex items-center text-gray-600">
                    <span className="w-1.5 h-1.5 bg-blue-500 rounded-full mr-2"></span>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Technologies;
