import React from 'react';
import { Database, Brain, Activity, Lock, Code, Globe } from 'lucide-react';

const Features = () => {
  const features = [
    {
      icon: <Database className="w-12 h-12 text-blue-500" />,
      title: 'Decentralization',
      description: 'True peer-to-peer interactions powered by blockchain technology, enabling direct value exchange without intermediaries.',
      details: [
        'Distributed networks',
        'Peer-to-peer transactions',
        'Blockchain technology',
        'Trustless systems'
      ]
    },
    {
      icon: <Brain className="w-12 h-12 text-blue-500" />,
      title: 'AI Integration',
      description: 'Smart systems that understand and process information like humans, enhancing user experiences through intelligent automation.',
      details: [
        'Machine Learning',
        'Natural Language Processing',
        'Automated systems',
        'Smart contracts'
      ]
    },
    {
      icon: <Activity className="w-12 h-12 text-blue-500" />,
      title: 'Semantic Web',
      description: 'Enhanced data connectivity through structured information, making web content machine-readable and interconnected.',
      details: [
        'Structured data',
        'Interconnected information',
        'Metadata enhancement',
        'Intelligent search'
      ]
    },
    {
      icon: <Lock className="w-12 h-12 text-blue-500" />,
      title: 'Enhanced Security',
      description: 'Advanced cryptographic methods ensuring data privacy and security in decentralized systems.',
      details: [
        'Cryptographic protection',
        'Secure transactions',
        'Privacy controls',
        'Identity management'
      ]
    },
    {
      icon: <Code className="w-12 h-12 text-blue-500" />,
      title: 'Smart Contracts',
      description: 'Self-executing contracts with terms directly written into code, automating and securing agreements.',
      details: [
        'Automated execution',
        'Transparent terms',
        'Reduced intermediaries',
        'Efficient processing'
      ]
    },
    {
      icon: <Globe className="w-12 h-12 text-blue-500" />,
      title: 'Interoperability',
      description: 'Seamless interaction between different platforms and protocols, creating a unified web experience.',
      details: [
        'Cross-platform compatibility',
        'Universal standards',
        'Data portability',
        'Unified protocols'
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Key Features of Web 3.0</h1>
          <p className="text-xl text-gray-600">Discover the revolutionary features shaping the future of the internet</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div 
              key={index} 
              className="bg-white rounded-lg shadow-md p-6 hover:shadow-xl transition-shadow duration-300"
            >
              <div className="flex items-center mb-4">
                {feature.icon}
              </div>
              <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
              <p className="text-gray-600 mb-4">{feature.description}</p>
              <ul className="space-y-2">
                {feature.details.map((detail, idx) => (
                  <li key={idx} className="flex items-center text-gray-600">
                    <span className="w-1.5 h-1.5 bg-blue-500 rounded-full mr-2"></span>
                    {detail}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="mt-16 bg-blue-50 rounded-xl p-8">
          <h2 className="text-2xl font-bold text-center mb-6">Why Web 3.0 Matters</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-4">
              <h3 className="text-xl font-semibold">For Users</h3>
              <ul className="space-y-2">
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                  Data ownership and control
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                  Enhanced privacy and security
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                  Improved user experience
                </li>
              </ul>
            </div>
            <div className="space-y-4">
              <h3 className="text-xl font-semibold">For Developers</h3>
              <ul className="space-y-2">
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                  Open development platforms
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                  Decentralized infrastructure
                </li>
                <li className="flex items-center">
                  <span className="w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                  Innovative building tools
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
