import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CourseModule from '../components/courses/CourseModule';
import CourseProgress from '../components/courses/CourseProgress';

const DAppDev = () => {
  const navigate = useNavigate();
  const [completedModules, setCompletedModules] = useState([]);

  const modules = [
    {
      id: 'web3-integration',
      title: 'Web3.js Integration',
      duration: '2 weeks',
      description: 'Learn how to integrate Web3.js with your frontend applications.',
      lessons: [
        { type: 'video', title: 'Web3.js Overview', duration: '20 min' },
        { type: 'reading', title: 'Provider Setup', duration: '25 min' },
        { type: 'code', title: 'Connecting to Smart Contracts', duration: '40 min' },
        { type: 'quiz', title: 'Web3.js Integration Quiz', duration: '15 min' }
      ]
    },
    {
      id: 'frontend-dev',
      title: 'Frontend Development',
      duration: '2 weeks',
      description: 'Build responsive and interactive DApp frontends.',
      lessons: [
        { type: 'video', title: 'React & Web3', duration: '25 min' },
        { type: 'reading', title: 'State Management', duration: '30 min' },
        { type: 'code', title: 'Building a DApp Interface', duration: '45 min' },
        { type: 'quiz', title: 'Frontend Development Quiz', duration: '20 min' }
      ]
    }
  ];

  const handleModuleComplete = (moduleId) => {
    setCompletedModules(prev => 
      prev.includes(moduleId) 
        ? prev.filter(id => id !== moduleId)
        : [...prev, moduleId]
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-3xl font-bold mb-2">DApp Development</h1>
        <p className="text-gray-600 mb-8">Build full-stack decentralized applications</p>

        <CourseProgress 
          progress={completedModules.length}
          totalModules={modules.length}
        />

        <div className="space-y-4">
          {modules.map((module, index) => (
            <CourseModule
              key={module.id}
              module={module}
              index={index}
              isCompleted={completedModules.includes(module.id)}
              onComplete={handleModuleComplete}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DAppDev;
